<template>
  <div class="words">
    <div class="words_center">
      <!-- 通知公告 -->
      <header>
        <div class="card_img">
          <img src="../assets/components/default.png" @click="gotopath(carddatas.id)" alt />
          <!-- <img :src="carddatas.pictureUrl" alt=""> -->
          <!-- 蒙版 -->
          <div class="imgshadow">
            <div class="p_collect">
              <!-- <i class="collect"></i> -->
              <span>起止时间：{{ carddatas.starttime }}至{{carddatas.endtime}}</span>
            </div>
          </div>
          <div class="serieslabel">
            <p>{{carddata.labelName}}</p>
          </div>
        </div>
      </header>
      <main>
        <!-- 标题 -->
        <div style="display: flex;">
          <el-tooltip class="item" effect="dark" :content="carddatas.title" placement="top">
            <p class="main_title" @click="gotopath(carddatas.id)">{{ carddatas.title }}</p>
          </el-tooltip>
        </div>
        <!-- 分类 -->
        <div class="labels">
          <p>{{ carddatas.label }}</p>
        </div>
        <div class="label">
          <el-tooltip class="item" effect="dark" :content="carddatas.periodName" placement="top">
            <p>{{ carddatas.periodName }}</p>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="carddatas.gradeName" placement="top">
            <p>{{ carddatas.gradeName }}</p>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="carddatas.subjectName" placement="top">
            <p>{{ carddatas.subjectName }}</p>
          </el-tooltip>
        </div>

        <!-- 创建信息 -->
        <div class="userinfo">
          <p class="userinf_title">
            <!-- <i class="el-icon-s-custom"></i> -->
            <i class="tpeople"></i>
            <span>{{ carddatas.member }}</span>
          </p>
          <p class="line"></p>
          <el-tooltip class="item" effect="dark" :content="carddatas.createName" placement="top">
            <p class="userinfo_time">发起者：{{ carddatas.createName }}</p>
          </el-tooltip>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    carddata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    gotopath(id) {
      this.$router.push({
        path: "/activityintroduce",
        query: {
          id: id,
        },
      });
    },
  },
  computed: {
    carddatas() {
      // console.log(this.carddata, "carddatas", this.Iam);
      let csdata = this.carddata;
      if (csdata !== null && csdata !== undefined) {
        if (csdata.periodName !== undefined && csdata.periodName.length > 1) {
          this.periodshow = true;
        }
        if (csdata.gradeName !== undefined && csdata.gradeName.length > 1) {
          this.gradeshow = true;
        }
        if (csdata.subjectName !== undefined && csdata.subjectName.length > 1) {
          this.subjectshow = true;
        }
        if (csdata.periodName !== undefined) {
          if (typeof csdata.periodName == "string") {
            csdata.periodName = csdata.periodName;
          } else {
            csdata.periodName = csdata.periodName.join("/");
          }
        }
        if (csdata.gradeName !== undefined) {
          if (typeof csdata.gradeName == "string") {
            csdata.gradeName = csdata.gradeName;
          } else {
            csdata.gradeName = csdata.gradeName.join("/");
          }
        }
        if (csdata.subjectName !== undefined) {
          if (typeof csdata.subjectName == "string") {
            csdata.subjectName = csdata.subjectName;
          } else {
            csdata.subjectName = csdata.subjectName.join("/");
          }
        }
        return csdata;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.words {
  width: 275px+325px;
  height: 184px;

  .words_center {
    display: flex;
    justify-content: flex-start;
    header {
      width: 275px;
      height: 165px;

      .card_img {
        position: relative;
        img {
          width: 275px;
          height: 165px;
          cursor: pointer;
        }
        .imgshadow {
          width: 275px;
          height: 24px;
          position: absolute;
          bottom: 4px;
          left: 0;
          background: linear-gradient(
            360deg,
            rgba(31, 28, 29, 0.29) 0%,
            rgba(239, 245, 255, 0.17) 88%
          );
          border-radius: 0px 0px 4px 4px;
          opacity: 1;
          display: flex;
          justify-content: flex-start;
          .p_collect {
            margin-left: 15px;
            // display: flex;
            // justify-content: center;
          }

          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
          }

          span {
            // width: 22px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;
          }

          .like {
            background: url("../assets/icon/like.png") no-repeat;
          }

          .collect {
            background: url("../assets/icon/collect.png") no-repeat;
          }

          .play {
            background: url("../assets/icon/play.png") no-repeat;
          }

          .comment {
            background: url("../assets/icon/comment.png") no-repeat;
          }
        }
        .serieslabel {
          padding: 5px 10px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          background: #2b7dee;
          //   width: 66px;
          //   height: 25px;

          opacity: 1;
          p {
            // width: 48px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
          }
        }
      }
    }

    main {
      margin-left: 10px;
      padding: 10px 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .main_title {
        max-width: 300px;
        // height: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .labels {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        p {
          max-width: 70px;
          max-height: 20px;
          margin-right: 10px;
          padding: 2px 5px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          //   border: 1px solid #d2d2d2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          //   color: #5d6066;

          background: #fdede3;
          border: 1px solid #ff8938;
          color: #ff8938;
        }
      }

      .label {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        p {
          max-width: 65px;
          max-height: 20px;
          margin-right: 10px;
          padding: 2px 5px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #d2d2d2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
        }
      }

      .userinfo {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        .userinf_title {
          // width: 36px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px;
        }
        .tpeople {
          display: inline-block;
          width: 14px;
          height: 13px;
          margin-right: 3px;
          background: url("../assets/icon/members.png") no-repeat;
        }
        .line {
          width: 0;
          height: 10px;
          margin-top: 3px;
          margin-left: 5px;
          border-left: 1px solid #8d9199;
        }

        .userinfo_time {
          margin-left: 5px;
          // width: 67px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px;
          max-width: 105px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>