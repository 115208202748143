<template>
  <div class="home">
    <div class="home_center">
      <!-- <h1>首页</h1> -->
      <!-- 背景 -->
      <!-- <div class="home_banner">
        <el-carousel height="493px">
          <el-carousel-item v-for="item in bannerData" :key="item.id">
            <img :src="item.url" alt />
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <!-- 链接 -->
      <!-- <div class="home_link">
        <el-card class="link_left box-card">
          <div class="link_l_title clearfix" slot="header">
            <p class="l_t_one">新闻动态</p>
            <p class="l_t_two" @click="gopath('10')">更多&gt;</p>
          </div>
          <div class="link_l_notice">
            <p v-for="item in noticeData" :key="item.id" @click="toNotice(item)">{{ item.notice }}</p>
          </div>
        </el-card>
        <el-card class="link_right">
          <div class="link_r_title" slot="header">
            <p>第三方链接</p>
          </div>
          <div class="link_r_div">
            <div class="link_r_up">
              <div class="link_img">
                <img src="../../assets/home/onesys.png" alt />
              </div>
              <p>综合评审系统</p>
            </div>
            <div class="link_r_up">
              <div class="link_img">
                <img src="../../assets/home/twosys.png" alt />
              </div>
              <p>OA办公系统</p>
            </div>
            <div class="link_r_up">
              <div class="link_img">
                <img src="../../assets/home/threesys.png" alt />
              </div>
              <p>继教系统</p>
            </div>
            <div class="link_r_up">
              <div class="link_img">
                <img src="../../assets/home/foursys.png" alt />
              </div>
              <p>智慧教科研评价平台</p>
            </div>
          </div>
        </el-card>
      </div> -->
      <!-- 概况 -->
      <div class="home_synopsis">
        <p class="h_s_title">门户数据概况</p>
        <el-card>
          <div class="h_s_card" v-if="synopsisData">
            <div class="h_s_banner h_s_one">
              <div>
                <p class="h_s_content">当前在线人数</p>
                <p class="h_s_text">
                  {{ synopsisData.nowLoginNum }}
                  <span>人</span>
                </p>
              </div>
              <div>
                <img src="../../assets/home/one.png" alt />
              </div>
            </div>
            <div class="h_s_banner h_s_two">
              <div>
                <p class="h_s_content">累计在线人数</p>
                <p class="h_s_text">
                  {{ synopsisData.sumLoginNum }}
                  <span>人</span>
                </p>
              </div>
              <div>
                <img src="../../assets/home/two.png" alt />
              </div>
            </div>
            <div class="h_s_banner h_s_three">
              <div>
                <p class="h_s_content">课程学习次数</p>
                <p class="h_s_text">
                  {{ synopsisData.playCount }}
                  <span>次</span>
                </p>
              </div>
              <div>
                <img src="../../assets/home/three.png" alt />
              </div>
            </div>
            <div class="h_s_banner h_s_four">
              <div>
                <p class="h_s_content">资料下载次数</p>
                <p class="h_s_text">
                  {{ synopsisData.resourceDownCount }}
                  <span>次</span>
                </p>
              </div>
              <div>
                <img src="../../assets/home/four.png" alt />
              </div>
            </div>
            <div class="h_s_banner h_s_five">
              <div>
                <p class="h_s_content">累计圈子总数</p>
                <p class="h_s_text">
                  {{ synopsisData.circleNum }}
                  <span>个</span>
                </p>
              </div>
              <div>
                <img src="../../assets/home/five.png" alt />
              </div>
            </div>
          </div>
          <div id="echart" style="width: 1200px; height: 400px"></div>
        </el-card>
      </div>

      <!-- 优秀课程 -->
      <div class="home_class">
        <p>
          <span class="ome">优秀课程</span>
          <span class="omen" @click="gopath('3')">更多 &gt;</span>
        </p>
        <div class="home_c_center">
          <card v-for="item in aClassData" :key="item.id" :carddata="item" :cardsite="0"></card>
          <div v-for="item in aClassnull" :key="item" style="width: 275px; height: 165px"></div>
        </div>
      </div>

      <!-- 系列课程 -->
      <div class="home_series">
        <p>
          <span class="ome">系列课程</span>
          <span class="omen" @click="gopath('series')">更多 &gt;</span>
        </p>
        <div class="home_c_center">
          <cards v-for="item in sClassData" :key="item.id" :carddata="item"></cards>
          <div v-for="item in sClassnull" :key="item" style="width: 275px; height: 165px"></div>
        </div>
      </div>

      <!-- 热门资源 -->
      <div class="home_hot">
        <p>
          <span class="ome">热门资源</span>
          <span class="omen" @click="gopath('4')">更多 &gt;</span>
        </p>
        <div class="home_c_centers">
          <word v-for="item in hClassData" :key="item.id" :carddata="item"></word>
        </div>
      </div>

      <!-- 热门圈子 -->
      <div class="home_circle">
        <p>
          <span class="ome">热门圈子</span>
          <span class="omen" @click="gopath('2')">更多 &gt;</span>
        </p>
        <div class="home_c_center">
          <writing v-for="item in cClassData" :key="item.id" :carddata="item"></writing>
          <div v-for="item in cClassnull" :key="item" style="width: 275px; height: 164px"></div>
        </div>
      </div>

      <!-- 热门通知公告 -->
      <!-- <div class="home_active">
        <p>
          <span class="ome">热门通知公告</span>
          <span class="omen" @click="gopath('5')">更多 &gt;</span>
        </p>
        <div class="home_c_center">
          <words v-for="item in acClassData" :key="item.id" :carddata="item"></words>
        </div>
      </div> -->

      <!-- 党建宣传 -->
      <div class="home_square">
        <!-- <div>
          <div class="card_title">
            <p class="title_l">党建宣传</p>
            <p class="title_more" @click="gopath('8')">更多 &gt;</p>
          </div>
          <div class="card_blank"></div>
          <el-card>
            <div class="p_list">
              <div class="list_p" v-for="item in sqClassData" :key="item.id">
                <p class="p_blue">{{ item.type }}</p>
                <el-tooltip effect="dark" :content="item.content" placement="top">
                  <p class="p_black" @click="gotopath(item)" style="width: 400px">{{ item.content }}</p>
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </div>
        <div>
          <div class="card_title">
            <p class="title_l">成果推广</p>
            <p class="title_more" @click="gopath('7')">更多 &gt;</p>
          </div>
          <div class="card_blank"></div>
          <el-card>
            <div class="p_list">
              <div class="list_p" v-for="item in diClassData" :key="item.id">
                <p class="p_blue">{{ item.type }}</p>
                <el-tooltip :content="item.content" placement="top">
                  <p class="p_black" @click="cutpage(item)">{{ item.content }}</p>
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </div> -->

        <!-- 党建宣传 -->
        <!-- <lists :cdata="sqClassData" :cTitle="'党建宣传'"></lists> -->
        <!-- 推广 -->
        <!-- <lists :cdata="diClassData" :cTitle="'成果推广'"></lists> -->
      </div>
    </div>
  </div>
</template>
<script>
import card from "../../components/card.vue";
import cards from "../../components/cards.vue";
import word from "../../components/word.vue";
import words from "../../components/words.vue";
import writing from "../../components/writing.vue";
// import lists from "../../components/lists.vue";
export default {
  data() {
    return {
      // banner图
      bannerData: [
        {
          id: 1,
          url: require("../../assets/home/banner.png"),
        },
        {
          id: 2,
          url: require("../../assets/home/banner2.jpg"),
        },
      ],
      // 通知公告
      noticeData: null,
      // 门户概况
      synopsisData: null,
      // 折线数据

      // 优秀课程
      aClassData: null,
      aClassnull: 0,
      // 系列课程
      sClassData: null,
      sClassnull: 0,
      // 热门资源
      hClassData: null,
      // 热门圈子
      cClassData: null,
      cClassnull: null,
      // 热门通知公告
      acClassData: null,
      // 党建宣传
      sqClassData: null,
      //成果推广
      diClassData: null,

      //
      xtime: null,
      ykey: null,
    };
  },
  created() {
    this.getbannerData(); //获取背景图
    this.getnoticeData(); //获取新闻动态
    this.queryCollectCount(); //获取门户概况
    this.getWeekLoginNum(); //折线图
    this.getaClassData(); //优秀课程
    this.getsClassData(); //
    this.gethClassData(); //
    this.getcClassData(); //
    this.getacClassData(); //
    this.getsqClassData(); //
    this.getdiClassData(); //
  },
  mounted() {
    // this.drawLine(); // 绘制折线图
  },
  methods: {
    // ------获取数据
    // 获取背景图
    getbannerData() {
      console.log("获取背景");
    },
    // 获取新闻动态
    getnoticeData() {
      let aa = [
        {
          id: 1,
          notice: "朝阳区教科院“教研战疫”系列报道之初中教研篇",
        },
        {
          id: 2,
          notice: "朝阳区教科院“教研战疫”系列报道之特殊教育篇",
        },
        {
          id: 3,
          notice: "朝阳区教科院“教研战疫”系列报道之统筹部署篇",
        },
        {
          id: 4,
          notice: "创建研究型学校 助力学校高质量发展",
        },
        {
          id: 5,
          notice: "优质资源协同赋能 专业支撑科学“双减”——教学研讨会顺利召开",
        },
        // {
        //   id: 6,
        //   notice:
        //     "公告标题公告标题公告标题公告标题公告标题公告标题公告标题公告标题公告标题公告标题公告标题6",
        // },
      ];
      this.noticeData = aa;
    },
    // 获取门户概况
    async queryCollectCount() {
      await this.$Http.queryCollectCount().then((res) => {
        console.log(res, "获取门户概况");
        this.synopsisData = res.data;
      });
    },
    // 获取折线图数据
    async getWeekLoginNum() {
      await this.$Http
        .getWeekLoginNum()
        .then((res) => {
          console.log(res, "获取折线图数据");
          let xData = [];
          let yData = [];
          for (let a = 0; a < res.data.length; a++) {
            xData.push(res.data[a].loginDate);
            yData.push(res.data[a].loginNum);
          }
          this.xtime = xData;
          this.ykey = yData;
        })
        .then(() => {
          this.drawLine(); // 绘制折线图
        });
    },
    // 绘制折线图
    drawLine() {
      console.log(this.xtime, this.ykey, "shuju");
      var myChart = this.$echarts.init(document.getElementById("echart"));
      var option = {
        xAxis: {
          type: "category",
          // data: [
          //   "2022-7-5",
          //   "2022-7-4",
          //   "2022-7-3",
          //   "2022-7-2",
          //   "2022-7-1",
          //   "2022-6-30",
          //   "2022-6-29",
          // ],
          data: this.xtime,
        },
        yAxis: {
          type: "value",
          minInterval: 1, //保证纵坐标显示成整数
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            // data: [0, 0, 0, 0, 0, 0, 0],
            data: this.ykey,
            type: "line",
          },
        ],
      };
      option && myChart.setOption(option);
    },
    // 优秀课程
    getaClassData() {
      this.$Http.queryFineCourse().then((res) => {
        console.log("获取优秀课程", res);
        if (res.data.total < 5) {
          this.aClassnull = 4 - res.data.total;
        }
        this.aClassData = res.data.list;
      });

      return;
      let aa = [
        {
          id: 1,
          title: "课程标题标",
          periodName: ["初中", "高中"],
          gradeName: ["八年级", "高三"],
          subjectName: ["语文", "数学"],
          createName: "刘老师",
          createTime: "2022-13-32 25:61:61",
          praiseCount: "100",
          collectionCount: "100",
          playCount: "1000",
          commentCount: "2000",
        },
        {
          id: 2,
          title: "课程标题2",
          periodName: ["初中", "高中"],
          gradeName: ["八年级", "高三"],
          subjectName: ["语文", "数学"],
          createName: "刘老师",
          createTime: "2022-13-32 25:61:61",
          praiseCount: "100",
          collectionCount: "100",
          playCount: "1000",
          commentCount: "2000",
        },
        {
          id: 3,
          title: "课程标题3",
          periodName: ["初中", "高中"],
          gradeName: ["八年级", "高三"],
          subjectName: ["语文", "数学"],
          createName: "刘老师",
          createTime: "2022-13-32 25:61:61",
          praiseCount: "100",
          collectionCount: "100",
          playCount: "1000",
          commentCount: "2000",
        },
        {
          id: 4,
          title: "课程标4",
          periodName: ["初中", "高中"],
          gradeName: ["八年级", "高三"],
          subjectName: ["语文", "数学"],
          createName: "刘老师",
          createTime: "2022-13-32 25:61:61",
          praiseCount: "100",
          collectionCount: "100",
          playCount: "1000",
          commentCount: "2000",
        },
      ];
      this.aClassData = aa;
    },
    // 系列课程
    getsClassData() {
      this.$Http.queryFineSeriseCourse().then((res) => {
        console.log("获取系列课程", res);
        if (res.data.total < 5) {
          this.sClassnull = 4 - res.data.total;
        }
        this.sClassData = res.data.list;
      });
      return;
      let aa = [
        {
          id: 1,
          seriseName: "课程标题1",
          // content: "初中/八年级/数学",
          createUser: "刘老师",
          createTime: "2022-01-02 25:61:61",
          courseCount: "6",
          praiseCount: "100",
          collectionCount: "100",
          // play: "1000",
          // notice: "2000",
        },
        {
          id: 2,
          seriseName: "课程标题2",
          // content: "初中/八年级/数学",
          createUser: "刘老师",
          createTime: "2022-01-02 25:61:61",
          courseCount: "6",
          praiseCount: "100",
          collectionCount: "100",
          // play: "1000",
          // notice: "2000",
        },
        {
          id: 3,
          seriseName: "课程标题3",
          // content: "初中/八年级/数学",
          createUser: "刘老师",
          createTime: "2022-01-02 25:61:61",
          courseCount: "6",
          praiseCount: "100",
          collectionCount: "100",
          // play: "1000",
          // notice: "2000",
        },
        {
          id: 4,
          seriseName: "课程标4",
          // content: "初中/八年级/数学",
          createUser: "刘老师",
          createTime: "2022-01-02 25:61:61",
          courseCount: "6",
          praiseCount: "100",
          collectionCount: "100",
          // play: "1000",
          // notice: "2000",
        },
      ];
      this.sClassData = aa;
    },
    // 热门资源
    gethClassData() {
      this.$Http.queryFineResource().then((res) => {
        console.log("获取热门资源", res);

        this.hClassData = res.data.list;
      });
      return;
      let aa = [
        {
          id: 1,
          pictureUrl: "课程图片",
          title: "课程标题1",
          gradeName: ["一年级", "二年级", "三年级"],
          createName: "刘老师",
          createTime: "2022-01-02 25:61:61",
          resouceSize: "100",
          resouceSizeUnit: "M",
          downCount: "100",

          // gradeName: "年级名称",
          // createName: "创建者",
          // createTime: "创建时间",
          // resouceSize: "资源大小",
          // resouceSizeUnit: "资源大小单位",
          // downCount: "下载量",
          // conent: "简介",
          // periodName: "学段名称",
          // subjectName: "学科名称",
          // subjectType: "学科类型",
        },
        {
          id: 2,
          pictureUrl: "课程图片",
          title: "课程标题2",
          gradeName: ["一年级", "二年级", "三年级"],
          createName: "刘老师",
          createTime: "2022-01-02 25:61:61",
          resouceSize: "100",
          resouceSizeUnit: "M",
          downCount: "100",
        },
        {
          id: 3,
          pictureUrl: "课程图片",
          title: "课程标题3",
          gradeName: ["一年级", "二年级", "三年级"],
          createName: "刘老师",
          createTime: "2022-01-02 25:61:61",
          resouceSize: "100",
          resouceSizeUnit: "M",
          downCount: "100",
        },
        {
          id: 4,
          title: "课程标4",
          gradeName: ["一年级", "二年级", "三年级"],
          createName: "刘老师",
          createTime: "2022-01-02 25:61:61",
          resouceSize: "100",
          resouceSizeUnit: "M",
          downCount: "100",
        },
      ];
      this.hClassData = aa;
    },
    // 热门圈子
    getcClassData() {
      this.$Http.queryFineCircle().then((res) => {
        console.log("获取热门圈子", res);
        if (res.data.total < 5) {
          this.cClassnull = 4 - res.data.total;
        }
        this.cClassData = res.data.list;
      });
      return;
      let aa = [
        {
          id: 1,
          title: "课程标题1",
          content: "初中/八年级/数学",
          user: "上传者：刘老师",
          number: "200",
          like: "100",
          star: "100",
        },
        {
          id: 2,
          title: "课程标题2",
          content: "初中/八年级/数学",
          user: "上传者：刘老师",
          number: "200",
          like: "100",
          star: "100",
        },
        {
          id: 3,
          title: "课程标题3",
          content: "初中/八年级/数学",
          user: "上传者：刘老师",
          number: "200",
          like: "100",
          star: "100",
        },
        {
          id: 4,
          title: "课程标4",
          content: "初中/八年级/数学",
          user: "上传者：刘老师",
          number: "200",
          like: "100",
          star: "100",
        },
      ];
      this.cClassData = aa;
    },
    //热门通知公告
    getacClassData() {
      let aa = [
        {
          id: 1,
          title: "一体化系统上线试运行通告",
          labelName: "系统上线/通知公告",
          label: "通知公告",
          periodName: ["小学", "初中"],
          gradeName: ["三年级", "九年级"],
          subjectName: ["综合"],
          member: "200",
          createName: "刘老师",
          starttime: "2022-09-01",
          endtime: "2022-12-01",
        },
        {
          id: 2,
          title: "一体化系统上线试运行问题反馈收集",
          labelName: "一体化系统/问题收集/系统上线",
          label: "资源征集",
          periodName: ["小学", "初中"],
          gradeName: ["三年级", "九年级"],
          subjectName: ["综合"],
          member: "150",
          createName: "刘老师",
          starttime: "2022-09-01",
          endtime: "2022-12-01",
        },
        {
          id: 3,
          title: "一体化系统上线试运行投票反馈",
          labelName: "系统上线/投票/一体化系统",
          label: "投票",
          periodName: ["幼儿教育", "小学"],
          gradeName: ["三年级", "九年级"],
          subjectName: ["综合"],
          member: "204",
          createName: "刘老师",
          starttime: "2022-09-01",
          endtime: "2022-12-01",
        },
        {
          id: 4,
          title: "一体化系统上线试运行问卷调查",
          labelName: "系统上线/试运行/问卷调查",
          label: "问卷调查",
          periodName: ["幼儿教育", "小学"],
          gradeName: ["三年级", "九年级"],
          subjectName: ["综合"],
          member: "204",
          createName: "刘老师",
          starttime: "2022-09-01",
          endtime: "2022-12-01",
        },
      ];
      this.acClassData = aa;
    },
    // 党建宣传
    getsqClassData() {
      let aa = [
        {
          id: 1,
          knum: 1,
          type: "支部党建新闻",
          content: "朝阳区教科院举办新学期师德专题教育大会",
        },
        {
          id: 2,
          knum: 2,
          type: "支部党建新闻",
          content: "发挥基层党组织战斗堡垒作用 汇聚朝阳高中教育发展新动能——区教科院高中教研部党支部召开组织生活会",
        },
        {
          id: 3,
          knum: 3,
          type: "支部党建新闻",
          content: "五四青年节学习感悟报道（二）",
        },
        {
          id: 4,
          knum: 4,
          type: "支部党建新闻",
          content: "扎实开展党史学习教育 发挥铸魂育人功能",
        },
        {
          id: 5,
          knum: 1,
          type: "学区工作宣传",
          content: "点赞收藏_线上学习效率低？看朝阳专家团队来支招！",
        },
        {
          id: 6,
          knum: 2,
          type: "学区工作宣传",
          content: "一图读懂 | 2022，朝阳教科院将这么干！",
        },
        {
          id: 7,
          knum: 3,
          type: "学区工作宣传",
          content: "以教科研促学校高质量发展——科研专项调研走进北京市第94中朝阳新城分校",
        },
        {
          id: 8,
          knum: 4,
          type: "学区工作宣传",
          content: "幼小衔接 我们在行动——朝阳区成功承办北京市幼小衔接阶段性教育成果经验交流会",
        },
      ];
      this.sqClassData = aa;
    },
    // 党建宣传详情
    gotopath(item) {
      this.$router.push({
        path: "/brandinfo",
        query: {
          id: item.knum,
          text: item.content,
          title: item.type,
        },
      });
    },
    // 成果推广
    getdiClassData() {
      let aa = [
        {
          id: 1,
          knum: 1,
          type: "国家教育教学成果",
          content: "立足区情 合作共研 科学规划——国家级幼儿园优秀教学成果朝阳区推广应用最新进展",
        },
        {
          id: 2,
          knum: 2,
          type: "国家教育教学成果",
          content: "马芯兰小学数学教学法”推广暨基础教育国家级优秀教学成果推广应用示范区（朝阳区）建设启动会隆重召开",
        },
        {
          id: 3,
          knum: 1,
          type: "市级教育教学成果",
          content: "“基于教学改革、融合信息技术的新型教与学模式”实验区市级线上成果展示会成功召开",
        },
        {
          id: 4,
          knum: 2,
          type: "市级教育教学成果",
          content: "激发儿童创造活力，实现课程整体育人 ——朝阳区教育研究中心联合组织课程整体育人市级现场会-九学科协同研究与实践“推广培训会隆重召开”",
        },
        {
          id: 5,
          knum: 1,
          type: "区级教育教学成果",
          content: "朝阳区第十七届教育教学成果表彰会暨2022年第十八届教育教学成果申报启动会召开",
        },
        {
          id: 6,
          knum: 2,
          type: "区级教育教学成果",
          content: "建构“十四五”高质量学前教育体系 医教结合共促儿童健康发展——朝阳区家园合作预防及应对小班幼儿卫生与健康教育问题研究",
        },
        {
          id: 7,
          knum: 1,
          type: "其他教育教学成果",
          content: "“工程思维”和“匠心精神”协同育人——我区职业高中课程思政的探索与实践",
        },
        {
          id: 8,
          knum: 2,
          type: "其他教育教学成果",
          content: "朝阳区第十五届教育教学成果公布啦！你的学校上榜了吗？ 融通一体促衔接",
        },
      ];
      this.diClassData = aa;
    },
    // 成果推广详情
    cutpage(item) {
      this.$router.push({
        path: "/diffusioninfo",
        query: {
          id: item.knum,
          text: item.content,
          title: item.type,
        },
      });
    },
    // ----更多跳转
    gopath(name) {
      console.log(name, "name");
      if (name == "series") {
        this.$router.push({
          name: "3",
          // path: "/acard",
          query: {
            id: 0,
          },
        });
      } else {
        this.$router.push({
          name: `${name}`,
          // path: "/acard",
          // query: {
          //   paths: name,
          // },
        });
      }
    },
    //---详情跳转
    toNotice(item) {
      console.log(item, "item");
      this.$router.push({
        path: "/newinfo",
        query: {
          id: item.id,
          text: item.notice,
        },
      });
    },
    // ----跳转系统
    gotoSystem(name) {
      this.$message.success("正在进入" + `${name}`);
    },
  },
  components: {
    card,
    cards,
    word,
    words,
    writing,
    // lists,
  },
};
</script>
<style lang="scss" scoped>
.home {
  .home_center {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    // padding: 20px 20px 20px;
    .home_banner {
    }
    .home_link {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      .link_left {
        width: 650px;
        // height: 200px;
        padding: 10px;
        .link_l_title {
          display: flex;
          justify-content: space-between;
          // margin-bottom: 10px;
          .l_t_one {
            width: 88px;
            height: 29px;
            font-size: 22px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 26px;
          }
          .l_t_two {
            width: 40px;
            height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            line-height: 16px;
            cursor: pointer;
          }
          .l_t_two:hover {
            color: #1977f9;
          }
        }
        .link_l_notice {
          // display: flex;
          // justify-content: space-between;
          // flex-wrap: wrap;
          p {
            width: 600px;
            height: 24px;
            padding: 10px 0;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #4f4f4f;
            line-height: 21px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:hover {
            color: #1977f9;
          }
        }
      }
      .link_right {
        width: 500px;
        // height: 200px;
        padding: 10px;
        .link_r_title {
          p {
            width: 110px;
            height: 29px;
            font-size: 22px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 26px;
          }
        }
        .link_r_div {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .link_r_up {
            width: 230px;
            // height: 176px;
            .link_img {
              margin: 0 auto;
              width: 64px;
              margin-top: 20px;
            }
            img {
              // width: 63px;
              height: 63px;
            }
            p {
              cursor: pointer;
              text-align: center;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .home_synopsis {
      .h_s_title {
        // margin: 50px 0 20px;
        margin: 0 0 20px;

        width: 132px;
        height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #1c0909;
        line-height: 29px;
      }
      .h_s_card {
        // width: 1200px;
        display: flex;
        justify-content: space-between;
        .h_s_banner {
          width: 200px;
          height: 88px;
          padding: 5px 10px;

          // background: #ffac00;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;

          display: flex;
          justify-content: space-between;
          .h_s_content {
            width: 84px;
            height: 18px;
            padding-top: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
          }
          .h_s_text {
            width: 84px;
            height: 24px;
            margin-top: 10px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
            span {
              font-size: 14px;
            }
          }
          img {
            margin-top: 10px;
          }
        }
        .h_s_one {
          background: #ffac00;
        }
        .h_s_two {
          background: #537efa;
        }
        .h_s_three {
          background: #846efc;
        }
        .h_s_four {
          background: #57c8bf;
        }
        .h_s_five {
          background: #ff7777;
        }
        #echart {
          margin-top: 20px;
        }
      }
    }
    .home_class,
    .home_series,
    .home_hot,
    .home_circle {
      .ome {
        // width: 88px;
        // height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #141516;
        // line-height: 26px;
      }
      .omen {
        // width: 28px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
        cursor: pointer;
      }
      .omen:hover {
        color: rgba(25, 119, 249, 0.5);
      }
      p {
        // width: 88px;
        // height: 29px;
        // font-size: 22px;
        // font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        // font-weight: bold;
        // color: #1c0909;
        // line-height: 29px;
        margin: 15px 0;
        padding: 0 10px 0 0;
        display: flex;
        justify-content: space-between;
      }
      .home_c_center {
        display: flex;
        justify-content: space-between;
      }
      .home_c_centers {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .home_active {
      .ome {
        // width: 88px;
        // height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #141516;
        // line-height: 26px;
      }
      .omen {
        // width: 28px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 16px;
        cursor: pointer;
      }
      .omen:hover {
        color: rgba(25, 119, 249, 0.5);
      }
      p {
        // width: 132px;
        // height: 29px;
        // font-size: 22px;
        // font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        // font-weight: bold;
        // color: #1c0909;
        // line-height: 29px;
        margin: 15px 0;
        padding: 0 10px 0 0;
        display: flex;
        justify-content: space-between;
      }
      .home_c_center {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .home_square {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .el-card {
        width: 570px;
        margin-top: 10px;
        .p_list {
          margin-top: 10px;
          .list_p {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .p_blue {
              width: 130px;
              height: 24px;
              font-size: 15px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 600;
              color: #1977f9;
              line-height: 21px;
            }
            .p_black {
              cursor: pointer;
              width: 380px;
              height: 24px;
              font-size: 15px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 21px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .card_title {
        display: flex;
        justify-content: space-between;
        .title_l {
          // width: 88px;
          height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: 600;
          color: #141516;
          // line-height: 26px;
        }
        .title_more {
          cursor: pointer;
          // width: 28px;
          // height: 18px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 16px;
        }
        .title_more:hover {
          color: rgba(25, 119, 249, 0.5);
        }
      }
      .card_blank {
        // width: 100%;
        margin: 20px 0;
        // height: 2px;
        // width: 570px;
        // height: 0px;
        opacity: 1;
        border: 1px solid #1977f9;
      }
    }
  }
}
</style>
