<template>
  <div class="cards">
    <div class="cards_center">
      <!-- 系列课程 -->
      <header>
        <div class="card_img">
          <!-- <img src="../assets/components/course.png" alt /> -->
          <img :src="carddata.picture.url" @click="gopath(carddata.id)" alt="">
          <div class="imgshadow">
            <div class="likediv">
              <i class="like"></i>
              <span class="p_like">{{ carddata.praiseCount }}</span>
            </div>
            <div class="colectdiv">
              <i class="collect"></i>
              <span class="p_collect">{{ carddata.collectionCount }}</span>
            </div>
          </div>
          <div class="serieslabel" :style="cc">
            <p>{{carddata.labelName}}</p>
          </div>
        </div>
      </header>
      <main>
        <div style="display: flex;">
          <el-tooltip class="item" effect="dark" :content="carddata.seriseName" placement="top">
            <p class="main_title" @click="gopath(carddata.id)">{{ carddata.seriseName }}</p>
          </el-tooltip>
        </div>
        <div class="label">
          <p>包含课程：{{ carddata.courseCount }}节</p>
        </div>
        <div class="userinfo">
          <p class="userinf_title">
            <i class="tpeople"></i>
            <el-tooltip class="item" effect="dark" :content="carddata.createUser" placement="top">
              <span>{{ carddata.createUser }}</span>
            </el-tooltip>
          </p>
          <p class="line"></p>
          <p class="userinfo_time">{{ carddata.createTime }}</p>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    carddata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 进入详情页
    gopath(id) {
      console.log(id, "系列课程页面");
      this.$router.push({
        path: "/series",
        query: {
          id: id,
        },
      });
    },
  },
    computed: {
      cc() {
        // console.log(this.carddata.labelName, "kehcen");
        if(this.carddata.labelName!==undefined ){
          return `background: #fa7a1e;`
        }else{
          return ``
        }

  //       if (this.carddata.labelName == "精品课程") {
  //         return `background: #fa7a1e;`;
  //       } else if (this.carddata.labelName == "优秀课程") {
  //         return `background: #FFAC00;`;
  //       } else if (this.carddata.labelName == "创新课程") {
  //         return `background: #1E4EFA;`;
  //       }
      },
    },
};
</script>
<style lang="scss" scoped>
.cards {
  width: 275px;
  height: 270px;

  .cards_center {
    header {
      width: 275px;
      height: 165px;

      .card_img {
        img{
          width: 275px;
          height: 165px;
          cursor: pointer;
        }
        position: relative;
        .imgshadow {
          width: 275px;
          height: 24px;
          position: absolute;
          bottom: 4px;
          left: 0;
          background: linear-gradient(
            360deg,
            rgba(31, 28, 29, 0.29) 0%,
            rgba(239, 245, 255, 0.17) 88%
          );
          border-radius: 0px 0px 4px 4px;
          opacity: 1;
          display: flex;
          justify-content: flex-start;
          .likediv {
            margin-left: 15px;
          }
          .colectdiv {
            margin-left: 27px;
          }

          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
          }

          span {
            // width: 22px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;
          }

          .like {
            background: url("../assets/icon/like.png") no-repeat;
          }

          .collect {
            background: url("../assets/icon/collect.png") no-repeat;
          }
        }
        .serieslabel {
          padding: 5px 10px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          // background: #fa7a1e;
          //   width: 66px;
          //   height: 25px;

          opacity: 1;
          p {
            // width: 48px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
          }
        }
      }
    }

    main {
      padding: 10px 5px;

      .main_title {
        max-width: 264px;
        // height: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .label {
        margin-top: 10px;

        p {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
        }
      }

      .userinfo {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        .userinf_title {
          // width: 36px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px; max-width: 90px;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .tpeople {
          display: inline-block;
          width: 14px;
          height: 13px;
          background: url("../assets/icon/people.png") no-repeat;
          margin-right: 3px;
        }
        .line {
          width: 0;
          height: 10px;
          margin-top: 3px;
          margin-left: 5px;
          border-left: 1px solid #8d9199;
        }

        .userinfo_time {
          margin-left: 5px;
          // width: 67px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px;
        }
      }
    }
  }
}
</style>