<template>
  <div class="writing">
    <div class="writing_center">
      <!-- 教研圈 -->
      <header>
        <div class="card_img">
          <!-- <img src="../assets/components/course.png" alt /> -->
          <img :src="carddatas.logoUrl" @click="topath" alt />
          <div class="imgshadow">
            <div>
              <i class="like"></i>
              <span class="p_like">{{ carddatas.praiseCount }}</span>
            </div>
            <div>
              <i class="collect"></i>
              <span class="p_collect">{{ carddatas.collectionCount }}</span>
            </div>
            <div>
              <i class="play"></i>
              <span class="p_play">{{ carddatas.memberCount }}</span>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div style="display: flex;">
          <el-tooltip class="item" effect="dark" :content="carddatas.researchCircleName" placement="top">
            <p class="main_title" @click="topath">{{ carddatas.researchCircleName }}</p>
          </el-tooltip>
        </div>
        <!-- <div class="label">
          <el-tooltip class="item" effect="dark" :content="carddatas.periodName" placement="top">
            <p>{{ carddatas.periodName }}</p>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="carddatas.gradeName" placement="top">
            <p>{{ carddatas.gradeName }}</p>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="carddatas.subjectName" placement="top">
            <p>{{ carddatas.subjectName }}</p>
          </el-tooltip>
        </div>-->
        <div class="userinfo">
          <p class="userinf_title">
            <i class="tpeople"></i>
            <el-tooltip class="item" effect="dark" :content="carddatas.createUserName" placement="top">
              <span>{{ carddatas.createUserName }}</span>
            </el-tooltip>
          </p>
          <p class="line"></p>
          <p class="userinfo_time">{{ carddatas.createTime }}</p>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    carddata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    topath() {
      console.log(this.carddata.id, "this.carddata.id");
      this.$router.push({
        path: "/circleintroduce",
        query: {
          introId: this.carddata.id,
        },
      });
    },
  },
  computed: {
    carddatas() {
      // console.log(this.carddata, "carddatas", this.Iam);
      let csdata = this.carddata;
      if (csdata !== null && csdata !== undefined) {
        if (csdata.periodName !== undefined && csdata.periodName.length > 1) {
          this.periodshow = true;
        }
        if (csdata.gradeName !== undefined && csdata.gradeName.length > 1) {
          this.gradeshow = true;
        }
        if (csdata.subjectName !== undefined && csdata.subjectName.length > 1) {
          this.subjectshow = true;
        }
        if (csdata.periodName !== undefined) {
          if (typeof csdata.periodName == "string") {
            csdata.periodName = csdata.periodName;
          } else {
            csdata.periodName = csdata.periodName.join("/");
          }
        }
        if (csdata.gradeName !== undefined) {
          if (typeof csdata.gradeName == "string") {
            csdata.gradeName = csdata.gradeName;
          } else {
            csdata.gradeName = csdata.gradeName.join("/");
          }
        }
        if (csdata.subjectName !== undefined) {
          if (typeof csdata.subjectName == "string") {
            csdata.subjectName = csdata.subjectName;
          } else {
            csdata.subjectName = csdata.subjectName.join("/");
          }
        }
        return csdata;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.writing {
  width: 275px;
  height: 270px;

  .writing_center {
    header {
      width: 275px;
      height: 165px;

      .card_img {
        position: relative;
        img {
          width: 275px;
          height: 164px;
          cursor: pointer;
        }
        .imgshadow {
          width: 275px;
          height: 24px;
          position: absolute;
          bottom: 4px;
          left: 0;
          background: linear-gradient(
            360deg,
            rgba(31, 28, 29, 0.29) 0%,
            rgba(239, 245, 255, 0.17) 88%
          );
          border-radius: 0px 0px 4px 4px;
          opacity: 1;
          display: flex;
          justify-content: flex-start;

          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin-left: 10px;
          }

          span {
            // width: 22px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;
            margin-right: 15px;
          }

          .like {
            background: url("../assets/icon/like.png") no-repeat;
          }

          .collect {
            background: url("../assets/icon/collect.png") no-repeat;
          }

          .play {
            background: url("../assets/icon/member.png") no-repeat;
          }

          .comment {
            background: url("../assets/icon/comment.png") no-repeat;
          }
        }
      }
    }

    main {
      padding: 10px 5px;

      .main_title {
        max-width: 264px;
        // height: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .label {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        p {
          max-width: 65px;
          max-height: 20px;
          margin-right: 10px;
          padding: 2px 5px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #d2d2d2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
        }
      }

      .userinfo {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;

        .userinf_title {
          // width: 36px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .tpeople {
          display: inline-block;
          width: 14px;
          height: 13px;
          margin-right: 3px;
          background: url("../assets/icon/people.png");
        }

        .line {
          width: 0;
          height: 10px;
          margin-top: 3px;
          margin-left: 5px;
          border-left: 1px solid #8d9199;
        }

        .userinfo_time {
          margin-left: 5px;
          // width: 67px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 14px;
        }
      }
    }
  }
}
</style>